<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    scrollable
    style="overflow-y: hidden !important"
  >
    <v-card tile style="max-height: 50vh">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span
            v-if="item && item.title"
            class="white--text text-uppercase font-weight-bold"
          >
            <v-icon color="white" large left
              >mdi-newspaper-variant-outline</v-icon
            >
            {{ item.title }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-card
          outlined
          style="max-height: calc(50vh - 80px); overflow-y: auto"
        >
          <div class="primary text-center pa-1">
            <span class="white--text font-weight-bold text-uppercase">{{
              $t("global.information")
            }}</span>
          </div>
          <v-simple-table dense>
            <template v-slot:default v-if="table.length > 0">
              <tbody>
                <tr v-for="cell in table">
                  <td class="font-weight-bold">
                    {{ cell.text }}
                  </td>
                  <td class="text-right">
                    {{ cell.value }}
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:default v-else>
              <tbody>
                <tr>
                  <td class="text-center">
                    <span class="font-weight-bold">{{
                      $t("audit_logs.no_info")
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const NOT_WANTED_HEADER = [
  "created_at",
  "updated_at",
  "deleted_at",
  "created_by",
  "updated_by",
  "port_locode",
  "creator_name",
  "updater_name",
  "locodes",
  "default_locode",
  "user_permissions",
  "style",
  "events",
  "status",
  "dependencies",
  "map_geom",
  "mooring_bitt_groups",
  "content",
  "uploading",
  "annotations",
  "data",
  "versions",
  "last_version",
  "base_layer",
  "public",
];

export default {
  props: ["open", "item"],
  data() {
    return {
      table: [],
    };
  },
  watch: {
    isOpen() {
      this.getTable();
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  methods: {
    getTable() {
      this.table = [];
      for (const [key, value] of Object.entries(this.item.data)) {
        if (!NOT_WANTED_HEADER.includes(key) && value) {
          let newValue = this.checkValue(value);
          this.table.push({
            text: this.$t(`audit_logs.${key}`),
            value: newValue,
          });
        }
      }
      this.table.sort((a, b) => a.text.localeCompare(b.text));
    },

    checkValue(value) {
      switch (value) {
        case true:
        case "true":
          return this.$t("global.yes");
        case false:
        case "false":
          return this.$t("global.no");
        default:
          if (
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/.test(value) ||
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
          ) {
            value = this.$options.filters.formatJSFullDatetime(
              new Date(value)
            );
          }
          return value;
      }
    },
  },
};
</script>
